import { Stack, Typography } from "@mui/material";

interface Props {
  headerText?: string;
}
const PriceHeader = ({ headerText }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems={"flex-end"}
      sx={{ lineHeight: 1, marginBottom: 4 }}
    >
      <Typography
        variant={"h3"}
        sx={{
          lineHeight: 0.9,
          color: "#F0750A",
          fontWeight: 700,
        }}
      >
        Q
      </Typography>
      <Typography
        variant={"h3"}
        sx={{
          lineHeight: 1,
          color: "#F0750A",
          fontSize: "2.2rem",
          fontWeight: 700,
        }}
      >
        UICK
      </Typography>
      <Typography
        variant={"h3"}
        sx={{
          lineHeight: 0.9,
          color: "#F0750A",
          fontWeight: 700,
        }}
      >
        P
      </Typography>
      <Typography
        variant={"h3"}
        sx={{
          lineHeight: 1,
          color: "#F0750A",
          fontSize: "2.2rem",
          fontWeight: 700,
        }}
      >
        RICER
      </Typography>
    </Stack>
  );
};

export default PriceHeader;
