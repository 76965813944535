import React from 'react';
import './App.css';
import Price from './PricerApp/Views/Price/Price';
import Layout from './PricerApp/Layout';

function App() {
  return (
    <Layout>
      <Price option={'option'} />
    </Layout>
  );
}

export default App;
