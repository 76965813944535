import { Box, Stack, Typography } from "@mui/material";

interface Props {
  countData: { eligibleCount: number; matchCount: number; totalCount: number };
  processTime?: number;
}

const ProductCounter = ({ countData, processTime }: Props) => {
  const { eligibleCount, matchCount, totalCount } = countData;

  const calcProcessSeconds = (processMs: number) => {
    console.log("process ms", processMs);
    return (processMs ?? 0) / 1000;
  };

  return (
    <Box sx={{ textAlign: "right", margin: ".5rem 0" }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Typography sx={{ whiteSpace: "nowrap", fontSize: ".8rem" }}>
          TOTAL: {totalCount ?? 0}
        </Typography>
        {/* <Typography sx={{ fontWeight: 700, margin: '0 .5rem' }} >|</Typography> */}
        <Typography sx={{ whiteSpace: "nowrap", fontSize: ".8rem", margin: '0 1rem' }}>
          MATCHING: {matchCount ?? 0}
        </Typography>
        {/* <Typography sx={{ fontWeight: 700, margin: '0 .5rem' }} >|</Typography> */}
        <Typography sx={{ whiteSpace: "nowrap", fontSize: ".8rem" }}>
          ELIGIBLE: {eligibleCount ?? 0}
        </Typography>
      </Stack>
      <Typography
        sx={{
          whiteSpace: "nowrap",
          fontSize: ".8rem",
          fontWeight: 700,
          fontStyle: "italic",
          color: "#F0750A",
        }}
      >
        {calcProcessSeconds(processTime ?? 0)} seconds
      </Typography>
    </Box>
  );
};

export default ProductCounter;
