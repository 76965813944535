export const priceLoan = async (requestData: any) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    request: requestData,
  });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let returnData: any = {};

  await fetch("http://pricer.dev.snaprules.com/summary", requestOptions)
    .then((response) => response.text())
    .then((result) => (returnData = JSON.parse(result)))
    .catch((error) => console.log("error", error));

  return returnData;
};
