import React from "react";
import { Box } from "@mui/material";
import PricingForm from "./PricingForm/PricingForm";
import PricingResults from "./Results/PricingResults";
import ProductCounter from "./ProductCounter";
import PriceHeader from "./PriceHeader";

interface Props {
  option: any;
}

const Price: React.FC<Props> = ({ option }: Props) => {
  const [processTime, setProcessTime] = React.useState<number>(0);
  const [pricingResponse, setPricingResponse] = React.useState<any>({});

  return (
    <Box>
      <PriceHeader />
      <PricingForm
        setProcessTime={setProcessTime}
        setPricingResponse={setPricingResponse}
      />
      {pricingResponse.num_product_eligible !== null &&
        pricingResponse.num_product_eligible !== undefined && (
          <ProductCounter
            countData={{
              eligibleCount: pricingResponse.num_product_eligible ?? 0,
              matchCount: pricingResponse.num_product_matches ?? 0,
              totalCount: pricingResponse.num_product_total ?? 0,
            }}
            processTime={processTime}
          />
        )}
      {pricingResponse?.products?.length > 0 && (
        <PricingResults resultsData={pricingResponse?.products ?? []} />
      )}
    </Box>
  );
};

export default Price;
