import { Box } from "@mui/material";

interface Props {
  children: React.ReactNode;
}
const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    // <Box sx={{ background: '#003057' }}>
    <Box sx={{ background: "#073e6a" }}>
      <Box
        sx={{
          padding: 2,
          paddingTop: 4,
          maxWidth: 1200,
          margin: "auto",
          minHeight: "100vh",
          boxSizing: "border-box",
          background: "#FFF",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
