import { Stack } from "@mui/material";
import React from "react";
import ProductCard from "./ProductCard";
import { ProductData } from "../types";

interface Props {
  resultsData: any;
}

const PricingResults: React.FC<Props> = ({ resultsData }: Props) => {
  console.log("SHOW RESULTS!");

  return (
    <Stack direction="row" flexWrap={'wrap'}>
      {resultsData.map((result: any, i: number) => (
        <ProductCard
          key={`${result.lender_name}-${result.product_id}-${i}`}
          productData={result as ProductData}
        />
      ))}
    </Stack>
  );
};

export default PricingResults