import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Checkbox,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { priceLoan } from "../../../Data/priceLoan";
import { LoanPurpose, ProductType, RefiType } from "../types";

interface Props {
  setProcessTime: React.Dispatch<React.SetStateAction<number>>;
  setPricingResponse: React.Dispatch<React.SetStateAction<any>>;
}

const PricingForm: React.FC<Props> = ({
  setProcessTime,
  setPricingResponse,
}: Props) => {
  // form states
  const [loanAmount, setLoanAmount] = React.useState("");
  const [loanTerm, setLoanTerm] = React.useState(360);
  const [ltv, setLtv] = React.useState("");
  const [fico, setFico] = React.useState("");
  const [loanPurpose, setLoanPurpose] = React.useState<LoanPurpose>("PURCHASE");
  const [refiType, setRefiType] = React.useState<RefiType>("");
  const [productType, setProductType] = React.useState<ProductType[]>([]);
  const [compType, setCompType] = React.useState("BORROWER");
  const [lockDays, setLockDays] = React.useState(30);
  const [fips, setFips] = React.useState(8117);

  const [lastRequest, setLastRequest] = React.useState<any>({});

  //   hard coded values
  const nmls = 319658;
  const limit = 200;
  // const offset = 50;

  const productTypeOptions: ProductType[] = [
    "CONV",
    "FHA",
    "VA",
    "USDA",
    "JUMBO",
  ];

  //   change handlers
  const handleLoanAmountChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLoanAmount(event.target.value);
  };

  const handleLtvChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLtv(event.target.value);
  };

  const handleFicoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFico(event.target.value);
  };

  const handleLoanTermChange = (event: SelectChangeEvent<number>) => {
    setLoanTerm(parseInt((event.target.value as string) ?? "0"));
  };

  const handleLoanPurposeChange = (event: SelectChangeEvent<string>) => {
    const val = event.target.value as LoanPurpose;
    if (val === "PURCHASE" && refiType !== "") {
      setRefiType("");
    }
    setLoanPurpose(val);
  };

  const handleRefiTypeChange = (event: SelectChangeEvent<string>) => {
    setRefiType(event.target.value as RefiType);
  };

  const handleProductTypeChange = (event: SelectChangeEvent<ProductType[]>) => {
    const {
      target: { value },
    } = event;
    setProductType(
      // On autofill we get a stringified value.
      typeof value === "string"
        ? (value.split(",") as ProductType[])
        : (value as ProductType[])
    );
  };

  const handleCompTypeChange = (event: SelectChangeEvent<string>) => {
    setCompType(event.target.value);
  };

  const handleLockDaysChange = (event: SelectChangeEvent<number>) => {
    setLockDays(event.target.value as number);
  };

  const handleFipsChange = (event: SelectChangeEvent<number>) => {
    setFips(event.target.value as number);
  };

  // helpers
  const validateInputs = (pricingInputs: any) => {
    const isValid =
      pricingInputs.loan_amount > 0 &&
      pricingInputs.fico > 299 &&
      pricingInputs.ltv > 0;
    return isValid;
  };

  const resetResults = () => {
    setPricingResponse({});
    setProcessTime(0);
  };

  const formatProductTypes = (selectedTypes: ProductType[]) => {
    if (selectedTypes.length === 0) {
      return "";
    } else {
      const formattedProductTypes = selectedTypes.join(",");
      return formattedProductTypes;
    }
  };

  const compileInputs = () => {
    const pricingInputs = {
      product_term_months: loanTerm,
      loan_amount: loanAmount ? parseInt(loanAmount) : 0,
      nmls: nmls,
      fips: fips,
      fico: fico ? parseInt(fico) : 0,
      product_type: formatProductTypes(productType),
      ltv: ltv ? parseFloat(ltv) : 0,
      purpose_type: loanPurpose,
      refi_type: refiType,
      lender_paid: compType === "LENDER",
      lock_term_days: lockDays,
      limit: limit,
    };
    return pricingInputs;
  };

  const submitPricing = async (pricingInputs: any) => {
    const now = Date.now();

    if (validateInputs(pricingInputs)) {
      console.log("Submit Pricing Request: ", pricingInputs);
      const response = await priceLoan(pricingInputs);
      console.log("Pricing Response", response);
      if (response) {
        setPricingResponse(response);
        const responseFinishTime = Date.now();
        setProcessTime(responseFinishTime - now);
      }
    } else {
      resetResults();
    }
  };

  React.useEffect(() => {
    const currentInputs = compileInputs();
    if (currentInputs !== lastRequest) {
      setLastRequest(currentInputs);
      submitPricing(currentInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loanAmount,
    loanTerm,
    ltv,
    fico,
    productType,
    refiType,
    loanPurpose,
    fips,
    lockDays,
    compType,
  ]);

  return (
    <Box>
      <Stack direction={"row"}>
        <Grid container spacing={2}>
          {/* LOAN PURPOSE */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel htmlFor="purpose-type-select" size="small" shrink>
                Loan Purpose
              </InputLabel>
              <Select
                variant="outlined"
                size="small"
                label="Loan Purpose"
                fullWidth
                notched
                inputProps={{
                  name: "Loan Purpose",
                  id: "purpose-type-select",
                }}
                value={loanPurpose}
                onChange={(e) => handleLoanPurposeChange(e)}
              >
                <MenuItem value="PURCHASE">Purchase</MenuItem>
                <MenuItem value="REFI">Refi</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* REFI TYPE */}
          {loanPurpose === "REFI" && (
            <Grid item xs={6} sm={3} lg={2}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel htmlFor="refi-type-select" size="small" shrink>
                  Refi Type
                </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  label="Refi Type"
                  fullWidth
                  notched
                  inputProps={{
                    name: "Refi Type",
                    id: "refi-type-select",
                  }}
                  value={refiType}
                  onChange={(e) => handleRefiTypeChange(e)}
                >
                  <MenuItem value="CASH_OUT">Cash Out</MenuItem>
                  <MenuItem value="RATE_TERM">Rate Term</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {/* PRODUCT TYPE */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                id="product-type-multi-select-label"
                size="small"
                shrink
              >
                Product Type
              </InputLabel>
              <Select
                labelId="product-type-multi-select-label"
                id="product-type-multi-select"
                fullWidth
                multiple
                notched
                value={productType}
                onChange={(e) => handleProductTypeChange(e)}
                input={<OutlinedInput label="Product Type" />}
                renderValue={(selected: ProductType[]) => selected.join(", ")}
                // MenuProps={MenuProps}
                size="small"
              >
                {productTypeOptions.map((typeOption) => (
                  <MenuItem key={typeOption} value={typeOption}>
                    <Checkbox checked={productType.indexOf(typeOption) > -1} />
                    <ListItemText primary={typeOption} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* COMP TYPE */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel htmlFor="comp-type-select">Comp Type</InputLabel>
              <Select
                variant="outlined"
                size="small"
                label="Comp Type"
                fullWidth
                inputProps={{
                  name: "Comp Type",
                  id: "comp-type-select",
                }}
                value={compType}
                onChange={(e) => handleCompTypeChange(e)}
              >
                <MenuItem value={"BORROWER"}>Borrower Paid</MenuItem>
                <MenuItem value={"LENDER"}>Lender Paid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* FICO */}
          <Grid item xs={6} sm={3} lg={2}>
            <TextField
              label="FICO"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              fullWidth
              value={fico}
              onChange={handleFicoChange}
            />
          </Grid>
          {/* LOAN AMOUNT */}
          <Grid item xs={6} sm={3} lg={2}>
            <TextField
              label="Loan Amount"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              size="small"
              value={loanAmount}
              onChange={handleLoanAmountChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* LTV */}
          <Grid item xs={6} sm={3} lg={2}>
            <TextField
              label="LTV"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              fullWidth
              value={ltv}
              onChange={handleLtvChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* PRODUCT TERM */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel htmlFor="product-term-select">
                Product Term
              </InputLabel>
              <Select
                variant="outlined"
                size="small"
                label="Product Term"
                fullWidth
                inputProps={{
                  name: "Product Term",
                  id: "product-term-select",
                }}
                value={loanTerm}
                onChange={(e) => handleLoanTermChange(e)}
              >
                <MenuItem value={360}>30 Year</MenuItem>
                <MenuItem value={300}>25 Year</MenuItem>
                <MenuItem value={240}>20 Year</MenuItem>
                <MenuItem value={180}>15 Year</MenuItem>
                <MenuItem value={120}>10 Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* LOCK TERM */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel htmlFor="lock-term-select">Lock Term</InputLabel>
              <Select
                variant="outlined"
                size="small"
                label="Lock Term"
                fullWidth
                inputProps={{
                  name: "Lock Term",
                  id: "lock-term-select",
                }}
                value={lockDays}
                onChange={(e) => handleLockDaysChange(e)}
              >
                <MenuItem value={15}>15 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value={45}>45 Days</MenuItem>
                <MenuItem value={60}>60 Days</MenuItem>
                <MenuItem value={75}>75 Days</MenuItem>
                <MenuItem value={90}>90 Days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* FIPS */}
          <Grid item xs={6} sm={3} lg={2}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel htmlFor="fips-select">FIPS</InputLabel>
              <Select
                variant="outlined"
                size="small"
                label="FIPS"
                fullWidth
                inputProps={{
                  name: "FIPS",
                  id: "fips-select",
                }}
                value={fips}
                onChange={(e) => handleFipsChange(e)}
              >
                <MenuItem value={8117}>8117-CO</MenuItem>
                <MenuItem value={8001}>8001-CO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default PricingForm;
