import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { ProductData } from "../types";

interface Props {
  productData: ProductData;
}

const ProductCard = ({ productData }: Props) => {
  return (
    <Card 
    elevation={2}
    sx={{ 
        minWidth: 275, 
        margin: 1, 
        // borderTop: "6px solid #8DC8E8",
        // borderTop: "6px solid #3A8FBF",
        border: '1px solid #33578A',
        boxSizing: 'border-box',
        transition: 'all .2s ease-in-out',
        ":hover": {
            cursor: 'pointer',
            transform: 'scale(1.05)',
        }
        }}>
      <CardContent>
        <Typography sx={{ fontWeight: 700 }}>
          {productData.product_display_name}
        </Typography>
        <Typography>Base Price | {productData.base_price}</Typography>
        <Typography>Price | {productData.price}</Typography>
        <Typography>Rate | {productData.rate.toFixed(3)}%</Typography>
        <Typography
          className="lender-label"
          sx={{ color: "#33578A", fontWeight: "bold" }}
        >
          Lender: {productData.lender_name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
